import classNames from "classnames"
import React, { PropsWithChildren, Children } from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

type Props = {
  sectionId: string
  cardWidth: "slim" | "normal"
  className?: string
}

SwiperCore.use([Navigation])

const Slider = ({
  sectionId,
  cardWidth,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Swiper
      spaceBetween={20}
      slidesPerView="auto"
      freeMode
      grabCursor
      containerModifierClass="-mr-gutter-xs-v2 sm-v2:-mr-gutter-sm-v2 md-v2:-mr-gutter-md-v2 lg-v2:-mr-gutter-lg-v2 xl-v2:-mr-gutter-xl-v2 xl-v2:!pr-0"
      navigation={{
        nextEl: `.swiper-next-${sectionId}`,
        prevEl: `.swiper-prev-${sectionId}`,
      }}
      watchOverflow
      breakpoints={{
        900: {
          spaceBetween: 24,
        },
        1200: {
          spaceBetween: 32,
        },
      }}
    >
      {/* TODO Note: the numbers below are calculated based on number of visible cards + the gap between them alongside the gutter to calculate the margin right of last card
       * this should be revisisted with swiper update to see if better way to handle this
       */}
      {Children.map(children, (child, index) => (
        <SwiperSlide
          key={`swiper-slide-${index}`}
          className={classNames(
            "grid h-auto w-[280px]",
            {
              "md-v2:w-[390px] lg-v2:w-[495px] last:mr-[calc(100vw-5.33vw-280px)] last:sm-v2:mr-[calc(100vw-4.17vw-580px)] last:md-v2:mr-[calc(100vw-4.17vw-810px)] last:lg-v2:mr-[calc(100vw-7.22vw-1026px)] last:xl-v2:mr-[calc(100vw-9.76vw-1024px)] last:4k-v2:mr-0":
                cardWidth === "normal",
            },
            {
              "md-v2:w-[340px] lg-v2:w-[390px] last:mr-[calc(100vw-5.33vw-280px)] last:sm-v2:mr-[calc(100vw-4.17vw-580px)] last:md-v2:mr-[calc(100vw-4.17vw-710px)] last:lg-v2:mr-[calc(100vw-7.22vw-1234px)] last:xl-v2:mr-[calc(100vw-9.76vw-1234px)] last:4k-v2:mr-0":
                cardWidth === "slim",
            }
          )}
        >
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
export default Slider
